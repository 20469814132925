import React from 'react';
import styled from 'styled-components';
import { Row, Col, Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '../components/layout';
import SEO from '../components/seo';
import OfficeCardComponent from '../components/contact-us-page/OfficeCardComponent';
import ContactFormComponent from '../components/contact-us-page/ContactFormComponent';

export const officeAddresses = [
  {
    name: `Manila`,
    address: `Ground Floor, Pacific Century Tower #1472-1476, Quezon Avenue, South Triangle, Quezon City, Metro Manila, Philippines 1103`,
    landline: `(+632) 8442 8888`,
    mobile: `(+63) 917 555 8222`,
    email: `inquiry@primephilippines.com`,
  },
  {
    name: `Davao`,
    address: `Fourth Floor, Topaz Tower,Damosa I.T. Park, J.P. Laurel Avenue, Lanang,Davao City, Davao Del Sur, Philippines 8000`,
    landline: `(+6382) 238 7576`,
    mobile: `(+63) 917 893 3995 / (+63) 917 818 2699`,
    email: `inquiry.davao@primephilippines.com`,
  },
  {
    name: `Cebu`,
    address: `Regus - Cebu, Park Centrale, 5th Floor, Park Centrale Building Jose Maria del Mar St, cor Abad St, Cebu City 6000 `,
    landline: `(+6332) 230 4930`,
    mobile: `(+63) 917 843 6134`,
    email: `inquiry.cebu@primephilippines.com`,
  },
];

const HeaderImageContactPage = styled.div`
  position: relative;
  min-height: 320px;
  background-image: url('../../images/contact-us/contact-header-image.jpg');
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
`;

const ContactFormWrapper = styled.div`
  & .ant-row:first-child {
    padding: 3rem 0px 1.5rem;
  }

  & .ant-row:last-child {
    padding: 1.5rem 0px;
  }

  .icon-list-item {
    display: flex;
    align-items: center;
    > :first-child {
      font-size: 15px;
    }
    > :last-child {
      margin-left: calc(1rem * 0.3);
    }
  }

  .office-locations {
    padding: 80px 50px !important;
    color: #003366;
  }

  .contact-content-wrap {
    h4,
    h5 {
      color: ${props => `${props.theme.blackPalette.original}`};
    }
  }

  @media only screen and (max-width: 576px) {
    clip-path: polygon(0 2%, 100% 0, 100% 100%, 0% 100%);
  }
`;

function ContactUsPage({ location }) {
  return (
    <Layout source="Contact Us Page">
      <SEO
        title="Contact Us"
        description="The Leading Commercial Real Estate Consultancy Firm in the Philippines."
        meta={[
          {
            property: 'og:url',
            content: location.href,
          },
          {
            name: 'keywords',
            content:
              'Real Estate Service Provider in PH,Real Estate Service in the Philippines,Real Estate Service Firm in PH,Real Estate Service Firm in the Philippines',
          },
        ]}
      />

      <div style={{ backgroundColor: '#f2f2f2AF' }}>
        <HeaderImageContactPage />
        <ContactFormWrapper>
          <Row gutter={[0, 20]}>
            <Col
              className="contact-content-wrap"
              xs={{ span: 22, offset: 1 }}
              md={{ span: 8, offset: 1 }}
              lg={{ span: 7, offset: 3 }}
              xl={{ span: 6, offset: 4 }}
              xxl={{ span: 4, offset: 6 }}
            >
              <div>
                <h4>How Can We Help?</h4>
                <p className="contact-content">
                  Tell us your concerns and we'll do our best to assist and get in touch with you. We look forward
                  to hearing from you.
                </p>
              </div>

              <Divider />

              <div>
                <h5>Inquiries</h5>
                <div className="icon-list-item">
                  <div>
                    <FontAwesomeIcon icon="envelope" fixedWidth />
                  </div>
                  <div>inqury@primephilippines.com</div>
                </div>
                <div className="icon-list-item">
                  <div>
                    <FontAwesomeIcon icon="phone-alt" fixedWidth />
                  </div>
                  <div>(+632) 8442 8888</div>
                </div>
              </div>

              <br />

              <div>
                <h5>Careers</h5>
                <div className="icon-list-item">
                  <div>
                    <FontAwesomeIcon icon="envelope" fixedWidth />
                  </div>
                  <div>careers@primephilippines.com</div>
                </div>
                <div className="icon-list-item">
                  <div>
                    <FontAwesomeIcon icon="phone-alt" fixedWidth />
                  </div>
                  <div>(+632) 7616 8554</div>
                </div>
              </div>
            </Col>

            <Col
              className="contact-content-wrap"
              xs={{ span: 22, offset: 1 }}
              md={{ span: 12 }}
              lg={{ span: 10, offset: 1 }}
              xl={{ span: 10, offset: 1 }}
              xxl={{ span: 8 }}
            >
              <div className="contact-content">
                <ContactFormComponent />
              </div>
            </Col>
          </Row>

          <Row className="office-locations">
            <Col span={24} align="center">
              <h3 className='office-title'>Office Locations</h3>
            </Col>
            {/* <Col
              // md={{ span: 22, offset: 1 }}
              // xs={{ span: 24 }}
              // lg={{ span: 20, offset: 2 }}
              // xl={{ span: 18, offset: 3 }}
              // xxl={{ span: 16, offset: 4 }}
              md={{ span: 22}}
              xs={{ span: 22 }}
              lg={{ span: 22 }}
              xl={{ span: 22 }}
              xxl={{ span: 22 }}
            > */}
              {officeAddresses.map(officeAddress => (
                <OfficeCardComponent
                  key={officeAddress.name}
                  colProps={{
                    md: {
                      span: 12,
                    },
                    lg: {
                      span: 8,
                    },
                  }}
                  name={officeAddress.name}
                  address={officeAddress.address}
                  landline={officeAddress.landline}
                  mobile={officeAddress.mobile}
                  email={officeAddress.email}
                />
              ))}
            {/* </Col> */}
          </Row>
        </ContactFormWrapper>
      </div>
    </Layout>
  );
}

export default ContactUsPage;
