import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col } from 'antd';

const StyledDiv = styled(Col)`
  padding: 16px;

  .office-content.location {
    color: ${props => props.theme.primeBluePalette.original};
    font-size: 20px;
  }

  .icon-list-item {
    display: flex;
    align-items: center;
    > :first-child {
      font-size: 15px;
    }
    > :last-child {
      margin-left: calc(1rem * 0.3);
    }
  }
`;

const OfficeCardComponent = ({
  name,
  address,
  landline,
  mobile,
  email,
  colProps,
}) => (
  <StyledDiv {...colProps}>
    <h5 className="office-content location">{name}</h5>
    <p className="office-content">{address}</p>

    <div className="icon-list-item">
      <div>
        <FontAwesomeIcon icon="phone-alt" fixedWidth />
      </div>
      <div>{landline}</div>
    </div>

    <div className="icon-list-item">
      <div>
        <FontAwesomeIcon icon="mobile-alt" fixedWidth />
      </div>
      <div>{mobile}</div>
    </div>

    <div className="icon-list-item">
      <div>
        <FontAwesomeIcon icon="envelope" fixedWidth />
      </div>
      <div>{email}</div>
    </div>
  </StyledDiv>
);

export default OfficeCardComponent;
